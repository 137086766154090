import * as React from "react";
import { logger } from "bernie-core";
import { StorefrontWizardRegionCOMETProps, StorefrontWizardRegionCOMETTemplateComponentConfig } from "./typings";
import {
  StorefrontWizardRegionTypesContext,
  StorefrontWizardRegionTypes,
} from "components/flexComponents/PageLevelProviders/WizardContextProvider/StorefrontWizardRegionTypesContext";
import { withLocalization } from "@shared-ui/localization-context";
import { LobQueryRouter } from "src/components/shared/StorefrontWizard/LobQueryRouter";
import {
  getNonWizardLOBTemplateComponents,
  getWizardLOBTemplateComponents,
} from "src/components/shared/StorefrontWizard/StorefrontWizardUtils";
import {
  TabbedWizardCard,
  WizardRegionType,
} from "src/components/shared/StorefrontWizard/TabbedWizardCard/TabbedWizardCard";
import { UitkFigure, UitkFigureAspectRatioType } from "@egds/react-core/images";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";
import { CustomerNotifications } from "components/shared/CustomerNotifications";
import { withStores } from "src/stores";
import { UitkScrim, UitkScrimType } from "@egds/react-core/scrim";
import { BlossomImage } from "src/components/shared/BlossomImage/BlossomImage";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkHeading, UitkSubheading } from "@egds/react-core/text";
import { WizardComponentTemplateComponentConfig } from "src/components/shared/StorefrontWizard/typings";
import { RegionChildrenList } from "src/utils/RegionUtils";

@withLocalization
@logger("StorefrontWizardRegionCOMET")
class StorefrontWizardRegionCOMET extends React.Component<StorefrontWizardRegionCOMETProps> {
  public render() {
    const { templateComponent, context, wizardState, compositionStore, blossomComponent } = this.props;

    if (!blossomComponent || !templateComponent.children || templateComponent.children.length < 1) {
      return null;
    }

    //default to false if device information is not available
    wizardState.globalWizardState.setIsDesktop(context.deviceInformation);

    const options = templateComponent.config as StorefrontWizardRegionCOMETTemplateComponentConfig;
    const { defaultLOB, cdgTreatment } = options;

    if (defaultLOB) {
      wizardState.globalWizardState.config.navigation.defaultLob = defaultLOB;
      wizardState.globalWizardState.config.navigation.activeLob = defaultLOB;
    }

    const wizardFormChildren = getWizardLOBTemplateComponents(templateComponent.children);
    const otherFormChildren = getNonWizardLOBTemplateComponents(templateComponent.children);
    const resolvedBackgroundImage =
      options.backgroundImage ||
      "https://forever.travel-assets.com/flex/flexmanager/images/2020/06/26/Orbitz-wizard-background.jpg";

    const isStorefrontPage = context.searchContext.pageType.indexOf("Storefront") > -1;
    const cometSFPadding =
      options.brandModulePadding && options.brandModulePadding === "comet_padding" ? "comet-homepage-module" : "";

    const subHeading =
      options.innerTitle || (compositionStore.composition!.pageSubHeadline && options.subTitleFromPageSubHeader);
    const heading = options.title || (compositionStore.composition!.pageHeading && options.titleFromPageHeader);

    const headingElement = (isMobile: boolean) => (
      <UitkSpacing
        padding={
          subHeading
            ? { small: { blockend: "three", blockstart: "six", inline: "six" }, medium: { blockend: "unset" } }
            : { small: { blockend: "six", blockstart: "six", inline: "six" }, medium: { blockend: "unset" } }
        }
      >
        <UitkHeading tag="h1" size={cdgTreatment && isMobile ? 4 : 2} className="SimpleContainer cometHeading">
          {options.titleFromPageHeader ? compositionStore.composition!.pageHeading : options.title}
        </UitkHeading>
      </UitkSpacing>
    );

    /**
     *
     * Note for modules below this region:
     * Modules not part of this region do not align to the form content out of the box
     * Setting the flex manager attribute brandModulePadding to comet_padding puts a spacing__twelve on the components below this region
     * This aligns them with the form content within the card on this wizard
     */
    return (
      <>
        <StorefrontWizardRegionTypesContext.Provider value={StorefrontWizardRegionTypes.STOREFRONT_WIZARD_REGION_COMET}>
          <div className={`StorefrontWizardRegionCOMET ${cometSFPadding}`} data-testid="storefront-region">
            <div data-testid="main-region" className="cometRegion cometBackgroundImageFigure">
              <UitkFigure ratio={UitkFigureAspectRatioType.R4_1} className="noContentVisibility">
                <BlossomImage src={resolvedBackgroundImage} alt="" imageRatio="4-1" />
                {cdgTreatment && <UitkScrim type={UitkScrimType.OVERLAY} />}
                {heading && (
                  <Viewport>
                    <ViewSmall>{headingElement(true)}</ViewSmall>
                    <ViewMedium>{headingElement(false)}</ViewMedium>
                  </Viewport>
                )}
                {heading && subHeading && (
                  <UitkSpacing
                    padding={{
                      small: { blockstart: "unset", blockend: "three", inline: "six" },
                      medium: { blockstart: "three", blockend: "unset" },
                    }}
                  >
                    <UitkSubheading tag="h2" className="SimpleContainer cometHeading">
                      {options.subTitleFromPageSubHeader
                        ? compositionStore.composition!.pageSubHeadline
                        : options.innerTitle}
                    </UitkSubheading>
                  </UitkSpacing>
                )}
                <LobQueryRouter lobsTemplateComponent={wizardFormChildren} />
                <Viewport>
                  <ViewSmall>
                    <>
                      {isStorefrontPage && (
                        <CustomerNotifications location="AFTER_HEADER" className="customerNotificationSquare" />
                      )}
                      <TabbedWizardCard
                        wizardFormChildren={wizardFormChildren}
                        wizardState={wizardState}
                        wrappingClassNames="SimpleContainer mobileCardRadius"
                        wizardRegionType={WizardRegionType.COMET}
                        blossomComponent={blossomComponent}
                      />
                    </>
                  </ViewSmall>
                  <ViewMedium>
                    <>
                      {isStorefrontPage && (
                        <UitkSpacing padding={{ inline: "six", blockstart: "twelve" }}>
                          <CustomerNotifications location="AFTER_HEADER" className="customerNotificationSquare" />
                        </UitkSpacing>
                      )}
                      <TabbedWizardCard
                        wizardFormChildren={wizardFormChildren}
                        wizardState={wizardState}
                        wrappingClassNames="SimpleContainer"
                        wrappingSpacing={{ padding: "six" }}
                        tabGroupClassNames="formAlignedTabs"
                        wizardFormClassNames="wizardOverHeroImage"
                        wizardFormSpacing={{ padding: { inline: "six" } }}
                        wizardRegionType={WizardRegionType.COMET}
                        blossomComponent={blossomComponent}
                      />
                    </>
                  </ViewMedium>
                </Viewport>
              </UitkFigure>
              {otherFormChildren.map((child) => (
                <div
                  key={`parent-${child.metadata.id}`}
                  className={
                    child.config && (child.config as WizardComponentTemplateComponentConfig).horizontalCenter === false
                      ? "prevent-justify-center"
                      : ""
                  }
                >
                  <RegionChildrenList templateComponents={[child]} blossomComponent={blossomComponent} />
                </div>
              ))}
            </div>
          </div>
        </StorefrontWizardRegionTypesContext.Provider>
      </>
    );
  }
}

export default withStores(
  "wizardState",
  "compositionStore",
  "flexModuleModelStore",
  "context"
)(StorefrontWizardRegionCOMET);
